import { initializeCarousel } from '../elements/carousels.js';

const testimonialClass = 'Block--coreTestimonial';

const options = {
  actionPrevClass: 'CoreTestimonial__Action--previous',
  actionNextClass: 'CoreTestimonial__Action--next',
  dotsClass: 'CoreTestimonial__Dots',
  dotClass: 'CoreTestimonial__Dot',
  dotCurrentClass: 'CoreTestimonial__Dot--current',
  itemsProperty: '--core-testimonial-items',
  itemsPerPageProperty: '--core-testimonial-items-per-page',
  indexProperty: '--core-testimonial-index',
  breakpoints: [720, 1024]
};

const $elements = document.querySelectorAll(`.${testimonialClass}`);
$elements.forEach($element => initializeCarousel($element, options));
