import { initializeCarousel } from '../elements/carousels.js';

const $coreCalendarCarousel = document.querySelector('.CoreCalendar');
const $coreCalendarCarouselPrev = document.querySelector('.CoreCalendar__Button--previous');
const $coreCalendarCarouselNext = document.querySelector('.CoreCalendar__Button--next');

const options = {
  actionPrev: $coreCalendarCarouselPrev,
  actionNext: $coreCalendarCarouselNext,
  itemsProperty: '--core-calendar-items',
  itemsPerPageProperty: '--core-calendar-items-per-page',
  indexProperty: '--core-calendar-index',
  breakpoints: [720, 1024],
  enableSwipe: 720,
  swipeOffsetProperty: '--core-calendar-swipe-offset',
  swipeTransitionLockClass: 'CoreCalendar--swiping',
  wrapOnFirstAndLast: false
}

if ($coreCalendarCarousel) {
  initializeCarousel($coreCalendarCarousel, options);
}
