function carouselItems($element, options) {
  const items = getComputedStyle($element).getPropertyValue(options.itemsProperty);
  return items ? parseInt(items, 10) : 0;
}

function carouselItemsPerPage($element, options) {
  const items = getComputedStyle($element).getPropertyValue(options.itemsPerPageProperty);
  return items ? parseInt(items, 10) : 0;
}

function carouselIndex($element, value, options) {
  if (arguments.length === 2) {
    options = value;
    value = undefined;
  }
  if (value === undefined) {
    const value = getComputedStyle($element).getPropertyValue(options.indexProperty);
    return value ? parseInt(value, 10) : 0;
  } else {
    $element.style.setProperty(options.indexProperty, value);
  }
}

function carouselSwipeOffset($element, value, options) {
  $element.style.setProperty(options.swipeOffsetProperty, value);
}

function carouselDot($element, index, options) {
  const $current = $element.querySelector(`.${options.dotCurrentClass}`);
  if ($current) {
    $current.classList.remove(options.dotCurrentClass);
  }

  const $dot = $element.querySelector(`.${options.dotClass}:nth-child(${index + 1})`);
  if ($dot) {
    $dot.classList.add(options.dotCurrentClass);
  }
}

function showEnoughDots($element, options) {
  const items = carouselItems($element, options);
  const itemsPerPage = carouselItemsPerPage($element, options);
  const $dots = $element.querySelectorAll(`.${options.dotClass}`);

  $dots.forEach(($dot, index) => {
    // Only show the dots that correspond to the first item of each carousel page.
    const show = index <= items - itemsPerPage && (index % itemsPerPage === 0 || index === items - itemsPerPage);
    $dot.classList.toggle(options.dotHiddenClass, !show);
  });
}

function syncActionButtons($element, options) {
  if (options.wrapOnFirstAndLast) {
    return;
  }
  const index = carouselIndex($element, options);
  const items = carouselItems($element, options);
  const itemsPerPage = carouselItemsPerPage($element, options);

  const enableNext = index < items - itemsPerPage;
  const enablePrev = index > 0;

  if (options.actionNext) {
    options.actionNext.disabled = !enableNext;
  }
  if (options.actionNextClass) {
    const actionNext = $element.querySelector(`.${options.actionNextClass}`);
    if (actionNext) {
      actionNext.disabled = !enableNext;
    }
  }
  if (options.actionPrev) {
    options.actionPrev.disabled = !enablePrev;
  }
  if (options.actionNextClass) {
    const actionPrev = $element.querySelector(`.${options.actionPrevClass}`);
    if (actionPrev) {
      actionPrev.disabled = !enablePrev;
    }
  }
}

function openPage($element, index, options) {
  carouselIndex($element, index, options);
  if (options.dotsClass) {
    carouselDot($element, index, options);
  }
  syncActionButtons($element, options);
}

function openPreviousPage($element, options) {
  const items = carouselItems($element, options);
  const itemsPerPage = carouselItemsPerPage($element, options);
  const index = carouselIndex($element, options);

  let previousPage = 0;
  if (index >= itemsPerPage) {
    previousPage = index - itemsPerPage;
  } else if (index === 0) {
    previousPage = items - itemsPerPage;
  }
  openPage($element, previousPage, options);
}

function openNextPage($element, options) {
  const items = carouselItems($element, options);
  const itemsPerPage = carouselItemsPerPage($element, options);
  const index = carouselIndex($element, options);

  let nextPage = items - itemsPerPage;
  if (index < items - itemsPerPage - itemsPerPage) {
    nextPage = index + itemsPerPage;
  } else if (index === items - itemsPerPage) {
    nextPage = 0;
  }
  openPage($element, nextPage, options);
}

function watchBreakpoints($element, options) {
  const handleMediaQueryChange = () => {
    showEnoughDots($element, options);
    openPage($element, 0, options);
  };

  for (const breakpoint of options.breakpoints) {
    matchMedia(`(min-width: ${breakpoint}px)`).addEventListener('change', handleMediaQueryChange);
  }
}

function watchSwipeBreakpoint(options) {
  matchMedia(`(min-width: ${options.enableSwipe}px)`).addEventListener('change', (event) => {
    /* The options object is passed around the functions by reference, so
     * setting this property here should affect the other functions that need
     * to check if swiping is enabled or not.
     */
    options._enableSwipeAtCurrentBreakpoint = window.innerWidth < options.enableSwipe;
  });
}

function enableSwipe($element, options) {
  let swipeStart;

  const getPointerX = event => {
    if (event instanceof TouchEvent) {
      return event.changedTouches[0].pageX;
    }
    return event.pageX;
  };
  const handleSwipeStart = event => {
    if (options._enableSwipeAtCurrentBreakpoint) {
      swipeStart = getPointerX(event);
      $element.classList.add(options.swipeTransitionLockClass);
    }
  };
  const handleSwipeMove = event => {
    if (swipeStart) {
      const offset = getPointerX(event) - swipeStart;
      carouselSwipeOffset($element, offset, options);
    }
  };
  const handleSwipeEnd = event => {
    if (swipeStart) {
      carouselSwipeOffset($element, 0.01, options);
      $element.classList.remove(options.swipeTransitionLockClass);

      const index = carouselIndex($element, options);
      const items = carouselItems($element, options);
      const offset = getPointerX(event) - swipeStart;

      if (offset < -options.swipeThreshold && index < items - 1) {
        openNextPage($element, options);
      } else if (offset > options.swipeThreshold && index > 0) {
        openPreviousPage($element, options);
      }
      swipeStart = null;
    }
  };

  $element.addEventListener('touchstart', handleSwipeStart);
  $element.addEventListener('mousedown', handleSwipeStart);
  document.addEventListener('touchmove', handleSwipeMove);
  document.addEventListener('mousemove', handleSwipeMove);
  document.addEventListener('touchend', handleSwipeEnd);
  document.addEventListener('mouseup', handleSwipeEnd);
}

/**
 *  enableSwipe <boolean>
 *    Whether to enable swipe gestures on the carousel or not. Possible values:
 *      true   - enable for all breakpoints
 *      false  - disable for all breakpoints
 *      number - enable below / disable above or equal this breakpoint
 *
 *  swipeThreshold <number>
 *    Accept the swipe gesture only when the swiped distance is greater than
 *    this value.
 *
 *  wrapOnFirstAndLast <boolean>
 *    true  - Opening next/previous page when at the last/first page wraps back
 *            to the first/last page.
 *    false - Disable corresponding action buttons when at the first/last page.
 */
const defaultOptions = {
  dotHiddenClass: 'Carousel__Hidden',
  enableSwipe: false,
  swipeThreshold: 30,
  wrapOnFirstAndLast: true,
};

export function initializeCarousel($element, options={}) {
  options = Object.assign({}, defaultOptions, options);

  if (options.dotsClass) {
    const index = carouselIndex($element, options);
    carouselDot($element, index, options);
  }

  if (options.breakpoints?.length ?? 0 > 0) {
    showEnoughDots($element, options);
    watchBreakpoints($element, options);
  }

  if (!options.wrapOnFirstAndLast) {
    syncActionButtons($element, options);
  }

  if (options.actionPrev) {
    options.actionPrev.addEventListener('click', () => openPreviousPage($element, options));
  }
  if (options.actionNext) {
    options.actionNext.addEventListener('click', () => openNextPage($element, options));
  }

  if (options.actionPrevClass || options.actionNextClass) {
    $element.addEventListener('click', event => {
      if (options.actionPrevClass && event.target.closest(`.${options.actionPrevClass}`)) {
        openPreviousPage($element, options);
      }
      if (options.actionNextClass && event.target.closest(`.${options.actionNextClass}`)) {
        openNextPage($element, options);
      }
    });
  }

  if (options.enableSwipe) {
    if (typeof options.enableSwipe === 'number') {
      options._enableSwipeAtCurrentBreakpoint = window.innerWidth < options.enableSwipe;
      watchSwipeBreakpoint(options);
    } else {
      options._enableSwipeAtCurrentBreakpoint = true;
    }
    enableSwipe($element, options);
  }
}
