import { initializeCarousel } from '../elements/carousels.js';

const bannerCarouselClass = 'Block--coreBannerCarousel';

const options = {
  actionPrevClass: 'CoreBannerCarousel__Action--previous',
  actionNextClass: 'CoreBannerCarousel__Action--next',
  dotsClass: 'CoreBannerCarousel__Dots',
  dotClass: 'CoreBannerCarousel__Dot',
  dotCurrentClass: 'CoreBannerCarousel__Dot--current',
  itemsProperty: '--core-banner-carousel-items',
  itemsPerPageProperty: '--core-banner-carousel-items-per-page',
  indexProperty: '--core-banner-carousel-index'
};

const $elements = document.querySelectorAll(`.${bannerCarouselClass}`);
$elements.forEach($element => initializeCarousel($element, options));
