const $page = document.querySelector('.Page');
if ($page) {
  $page.addEventListener('click', event => {
    const $twitterButton = event.target.closest('.js-twitter-share-button');
    if ($twitterButton) {
      event.preventDefault();
      const tweetURL = new URL($twitterButton);
      const $headingBlock = document.querySelector('.Block--coreHeading');
      if ($headingBlock) {
        const $heading = $headingBlock.querySelector('.CoreHeading');
        const title = $heading.textContent.trim();
        const newValue = title + tweetURL.searchParams.get('text');
        tweetURL.searchParams.set('text', newValue);
      }
      window.open(tweetURL,'popup','width=600,height=600,top=0');
    }
  });
}
